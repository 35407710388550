@import '../../../assets/sass/custom';

.dt_share {
  &::after {
    content: '';
    position: absolute;
    top: -10rem;
    right: -10rem;
    width: 25rem;
    height: 25rem;
    background-color: var(--bs-primary);
    border-radius: $border-radius-circle;
  }

  .dt_share_graph {
    right: -4rem;
    top: -2rem;
    width: 55rem;
  }

  .dt_shareTop {
    .dt_shareTop_Dots {
      width: 9rem;
    }

    .dt_share_cross {
      top: 5rem;
      left: 30%;
      transform: translateX(-30%);
      width: 7rem;
      height: 7rem;
    }

    .dt_shareTop_Left {
      .dt_Logo {
        max-width: 20rem;
        max-height: 12rem;
      }

      .dt_share_statBox {
        height: 7rem;
        min-width: 9.5rem;

        &.yellow {
          background-color: #faedc7;
        }

        &.red {
          background-color: #f6e0d8;
        }

        &.blue {
          background-color: #e0f4f4;
        }

        &:last-child {
          margin-right: 0 !important;
        }
      }
    }

    .dt_shareTop_Card {
      .dt_shareTop_CardIn {
        padding: 2rem 2rem;

        .dt_shareTop_Card_Icon {
          width: 8.5rem;
          height: 8.5rem;
        }
      }

      &:first-child {
        .dt_shareTop_CardIn {
          .dt_shareTop_Card_Graph {
            width: auto;
            height: 2.5rem;
            bottom: 3rem;
            left: -5.5rem;
          }
        }
      }

      &:nth-child(2) {
        .dt_shareTop_CardIn {
          padding: 4rem 2rem;
        }
      }

      &:last-child {
        .dt_shareTop_CardIn {
          padding: 5rem 2rem;
        }
      }
    }
  }

  .easytoFindCard {
    background-color: #f3f3f3;

    .easytoFindCardIcon {
      width: 6rem;
      height: 6rem;
    }
  }

  .particle-container {
    background-color: $white;
    position: relative;
    height: 70rem;
    width: 100%;
    overflow: hidden;
  }

  .particles {
    width: 100%;
    height: 100%;
    position: relative;

    .circle {
      border-radius: $border-radius-circle;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      overflow: hidden;

      img {
        position: relative;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  @for $i from 1 through 31 {
    @-webkit-keyframes particle-animation-#{$i} {
      0% {
        -webkit-transform: translate3d((random(70) * 1vw), (random(100) * 1vh), (random(300) * 1px)) rotate(random(90) + deg);
        transform: translate3d((random(70) * 1vw), (random(100) * 1vh), (random(300) * 1px));
      }

      100% {
        -webkit-transform: translate3d((random(70) * 1vw), (random(100) * 1vh), (random(300) * 1px)) rotate(random(180) + deg);
        transform: translate3d((random(70) * 1vw), (random(100) * 1vh), (random(300) * 1px));
      }
    }

    @keyframes particle-animation-#{$i} {
      0% {
        -webkit-transform: translate3d((random(70) * 1vw), (random(100) * 1vh), (random(110) * 1px)) rotate(random(90) + deg);
        transform: translate3d((random(70) * 1vw), (random(100) * 1vh), (random(300) * 1px));
      }

      100% {
        -webkit-transform: translate3d((random(70) * 1vw), (random(100) * 1vh), (random(300) * 1px)) rotate(random(180) + deg);
        transform: translate3d((random(70) * 1vw), (random(100) * 1vh), (random(300) * 1px));
      }
    }

    @-webkit-keyframes fade-frames {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    @keyframes fade-frames {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    .particles span:nth-child(#{$i}) {
      -webkit-animation: particle-animation-#{$i} 10s ease-in infinite,
      fade-frames 10s ease-in-out infinite;
      animation: particle-animation-#{$i} 10s ease-in infinite,
      fade-frames 10s ease-in-out infinite;
      $size: random(150)+5+px;
      height: $size;
      width: $size;
      $color: random(255);
      background-color: rgb($color, $color, $color);
      -webkit-animation-delay: -$i + s;
      animation-delay: -$i + s;
    }
  }
}

@media screen and (max-width: 991px) {
  .dt_share {
    .dt_share_graph {
      width: 45rem;
    }

    .dt_shareTop {
      .dt_shareTop_Dots {
        width: 6rem;
      }

      .dt_shareTop_Left {
        .dt_share_statBox {
          min-width: 8.8rem;
        }
      }

      .dt_shareTop_Card {
        .dt_shareTop_CardIn {
          padding: 1rem;
          border-width: 4px !important;

          .dt_shareTop_Card_Icon {
            width: 6.5rem;
            height: 6.5rem;
          }

          h4 {
            font-size: 1.6rem;
          }

          .parcent {
            font-size: 3rem !important;
          }
        }

        &:first-child {
          .dt_shareTop_CardIn {
            .dt_shareTop_Card_Graph {
              height: 2rem;
              left: -5rem;
            }
          }
        }

        &:nth-child(2) {
          .dt_shareTop_CardIn {
            padding: 3rem 2rem;
          }
        }

        &:last-child {
          .dt_shareTop_CardIn {
            padding: 4rem 2rem;
          }
        }
      }
    }

    .easytoFindCard {
      h4 {
        font-size: $h4-font-size;
      }
    }

    @for $i from 1 through 31 {
      @-webkit-keyframes particle-animation-#{$i} {
        0% {
          -webkit-transform: translate3d((random(80) * 1vw),
              (random(100) * 1vh),
              (random(400) * 1px)) rotate(random(90) + deg);
          transform: translate3d((random(80) * 1vw), (random(100) * 1vh), (random(400) * 1px));
        }

        100% {
          -webkit-transform: translate3d((random(80) * 1vw),
              (random(100) * 1vh),
              (random(400) * 1px)) rotate(random(180) + deg);
          transform: translate3d((random(80) * 1vw), (random(100) * 1vh), (random(400) * 1px));
        }
      }

      @keyframes particle-animation-#{$i} {
        0% {
          -webkit-transform: translate3d((random(80) * 1vw),
              (random(100) * 1vh),
              (random(110) * 1px)) rotate(random(90) + deg);
          transform: translate3d((random(80) * 1vw), (random(100) * 1vh), (random(400) * 1px));
        }

        100% {
          -webkit-transform: translate3d((random(80) * 1vw),
              (random(100) * 1vh),
              (random(400) * 1px)) rotate(random(180) + deg);
          transform: translate3d((random(80) * 1vw), (random(100) * 1vh), (random(400) * 1px));
        }
      }

      .particles span:nth-child(#{$i}) {
        $size: random(120)+5+px;
        height: $size;
        width: $size;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .dt_share {
    &::after {
      width: 21rem;
      height: 21rem;
    }

    .dt_share_graph {
      width: 30rem;
    }

    .dt_shareTop {
      .dt_shareTop_Dots {
        width: 6rem;
      }

      .dt_shareTop_Left {
        h4 {
          font-size: $h3-font-size;
        }
      }

      .dt_shareTop_Card {
        .dt_shareTop_CardIn {
          padding: 1.5rem !important;
          border-width: 3px !important;

          .dt_shareTop_Card_Icon {
            width: 5rem;
            height: 5rem;
          }

          h4 {
            font-size: $h4-font-size;
            flex: 1;
            margin: 0 1rem;
          }

          .parcent {
            font-size: $h1-font-size;
          }

          .dt_shareTop_Card_Graph {
            display: none;
          }
        }
      }
    }

    .particle-container {
      height: 45rem;
    }

    @for $i from 1 through 31 {
      @-webkit-keyframes particle-animation-#{$i} {
        0% {
          -webkit-transform: translate3d((random(100) * 1vw),
              (random(80) * 1vh),
              (random(600) * 1px)) rotate(random(90) + deg);
          transform: translate3d((random(100) * 1vw), (random(80) * 1vh), (random(600) * 1px));
        }

        100% {
          -webkit-transform: translate3d((random(100) * 1vw),
              (random(80) * 1vh),
              (random(600) * 1px)) rotate(random(180) + deg);
          transform: translate3d((random(100) * 1vw), (random(80) * 1vh), (random(600) * 1px));
        }
      }

      @keyframes particle-animation-#{$i} {
        0% {
          -webkit-transform: translate3d((random(100) * 1vw),
              (random(80) * 1vh),
              (random(600) * 1px)) rotate(random(90) + deg);
          transform: translate3d((random(100) * 1vw), (random(80) * 1vh), (random(600) * 1px));
        }

        100% {
          -webkit-transform: translate3d((random(100) * 1vw),
              (random(80) * 1vh),
              (random(600) * 1px)) rotate(random(180) + deg);
          transform: translate3d((random(100) * 1vw), (random(80) * 1vh), (random(600) * 1px));
        }
      }

      .particles span:nth-child(#{$i}) {
        $size: random(80)+5+px;
        height: $size;
        width: $size;
      }
    }
  }
}